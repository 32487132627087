import jQuery from 'jquery';
const $ = window.$ = jQuery;

import Controller from './modules/_controller';
import MainVisual from './modules/_mainVisual';
import Search from './modules/_search';
import Modal from './modules/_modal';
import Plan from './modules/_plan';

(function($){

	Controller.standby();

	MainVisual.init();

	Search.init();

	Modal.init();

	Plan.init();

	var Utility = {

		init: function() {
			$('.acc-toggle').on('click', this.toggle.bind(this));
			$(window).on('resize:responsive', this.reset.bind(this));

		},
		reset: function() {
			$('.item-inner').removeAttr('style');
		},
		toggle: function(el) {
			if(Controller.device === 'SP') {
				$(el.currentTarget).toggleClass('is-active');
				var wrap = $(el.currentTarget).prev('.item-inner');
				wrap.slideToggle(300)
			}
		}

	}

	Utility.init();


})(jQuery)
