import Swiper, { Navigation, Pagination } from 'swiper';


const MainVisual = {

	init: function() {
		let swipeOption = {
			loop: true,
			effect: 'fade',
			autoplay: {
				// delay: 4000,
				disableOnInteraction: false,
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true ,
				},
			speed: 2000,
		}

		$('.swiper-container').each(function(){

			var slide = $(this).find('.swiper-slide');
			if(slide.length > 1) {
				new Swiper(this, swipeOption);
			}
		})


	}

}

export default MainVisual;
