
import Controller from './_controller';

const Modal = {

	init: function () {

		this.view = {};

		this.scrollY = null;

		$(document).on('click', '.open-modal', this.openModal.bind(this));

		$(document).on('click', '.close-modal', this.closeModal.bind(this));

		$(document).on('click', '.open-video', this.openVideo.bind(this));

		$(document).on('click', '.close-video', this.closeVideo.bind(this));
	},
	openVideo: function(el) {

		var src = $(el.currentTarget).data('movie');

		var video = document.createElement('video');
		video.controls = true;
		video.muted = true;
		video.autoplay = false;
		video.loop = false;
		video.setAttribute('playsinline', '');
		video.src = src;
		document.getElementById('player').appendChild(video);

	},
	closeVideo: function() {
		$('#player').find('video').remove();
	},
	openModal: function (el) {

		var modalName = $(el.currentTarget).data('modal');
		var element = document.getElementsByClassName(modalName);
		this.scrollY = Controller.view.window.y;

		var ua = window.navigator.userAgent.toLowerCase();
		//ブラウザのsafariを判定
		var isSafari =  ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1
		//iosかどうかを判定
		var isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
		if(isSafari && !isiOS) {
			$('body').css({'overflow':'hidden'});
		} else {
			$('body').css({
				position: 'fixed',
				width: '100%',
				left: '0',
				top: -1 * this.scrollY,
			});
		}


		$(element).fadeIn(300);

	},
	closeModal: function (el) {

		var target = $(el.currentTarget).parents('.g-modal-frame');
		var top = $('body').css('top');
		top = Math.abs(parseInt(top));
		$('body').removeAttr('style');

		var ua = window.navigator.userAgent.toLowerCase();
		var isSafari =  ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1
		var isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
		if(!isSafari || (isSafari && isiOS)) {
			$('body, html').prop({ scrollTop: top });
		}

		$(target).fadeOut(300);
	},
}

export default Modal;
