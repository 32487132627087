import Controller from './_controller';

const Plan = {

	init:function() {
		$('.ellipsis').on('click',this.ellipsis.bind(this))
	},
	ellipsis: function(el) {
		$(el.currentTarget).addClass('hide');
		var element = $(el.currentTarget).parents('.ellipsis-text');
		var text = element.data('text');
		element.addClass('is-open');
		element.text(text);
	}

}

export default Plan;
