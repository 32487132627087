
import Controller from './_controller';

import ScrollMagic from 'ScrollMagic';

import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

const Search = {

	init: function(){

		this.loadFlag = true;
		$(window).on('load resize', this.setHeight.bind(this));

		this.firstClick = true;
		$('.search-btn').on('click', this.toggle.bind(this));

		$('.sp-search-btn').on('click', this.open.bind(this));

		$('.search-close').on('click', this.close.bind(this));

		this.fixedPC();
		this.fixedSP();

		$('.search-container .input').on('change', this.change.bind(this));

		$('.events .clear').on('click',this.clear.bind(this));

		$(window).on('resize', this.resizeHeight.bind(this));

	},
	resizeHeight: function(){
		if(Controller.device === 'PC') {
			var h = $('.search-fixed-wrapper').height();
			if($('.search-btn').hasClass('is-active')) {
				$('.search-btn').css({'transform': 'translateY(' + h + 'px)'});
			}

		}　else {
			$('.search-btn').removeClass('is-active');
			$('.search-btn').removeClass('is-fixed');
			$('.search-btn').css({'transform': 'translateY(' + 0 + 'px)'});
		}
	},
	setHeight() {

		if(Controller.device === 'PC') {
			var h =	$('.search-container').height();
			$('.search-container-wrapper').css('height', h + 'px')
			if(this.loadFlag) {
				$('.search-container-wrapper').addClass('init');
				this.loadFlag = false;
			}
		}
	},
	fixedPC: function() {
		if(!$('.search-container').get(0)) {
			return
		}
		this.controller = new ScrollMagic.Controller();
		var _this = this;

		var anime = new ScrollMagic.Scene({
				triggerElement: '.plans-container-body',
				triggerHook: 'onLeave',
				reverse: true,
				duration: this.pcHeight
		})
		// .addIndicators( {name: "scroll"})
		.addTo(this.controller);
		anime.on('enter', function (event) {
			$('.search-btn').addClass('is-fixed');
			$('.search-fixed-wrapper').addClass('is-fixed');
		});
		anime.on('leave', function (event) {
			$('.search-btn').removeClass('is-fixed');
			$('.search-btn').css({'transform': 'translateY(' + 0 + 'px)'});

			$('.search-fixed-wrapper').removeClass('is-fixed');
			$('.search-fixed-wrapper').removeClass('is-close')
			$('.search-fixed-wrapper').removeClass('is-open');

			_this.firstClick = true;
		});
	},
	pcHeight: function() {
		return $('.plans-container-body').height();
	},
	spHeight: function(){
		return $('.plans-container-body').height() + $('.sp-fixed-area-wrap').height();
	},
	fixedSP: function() {
		if(!$('.search-container').get(0)) {
			return
		}
		this.controller = new ScrollMagic.Controller();

		var anime = new ScrollMagic.Scene({
				triggerElement: '.sp-fixed-area-wrap',
				triggerHook: 'onLeave',
				reverse: true,
				duration: this.spHeight
		})
		// .addIndicators( {name: "scroll"})
		.addTo(this.controller);
		anime.on('enter', function (event) {
			$('.sp-fixed-area').addClass('is-fixed')
		});
		anime.on('leave', function (event) {
			$('.sp-fixed-area').removeClass('is-fixed');
		});
	},
	toggle: function(el) {
		if(Controller.device === 'PC') {
			$(el.currentTarget).toggleClass('is-active');
			var h = $('.search-fixed-wrapper').height();



			if($('.search-fixed-wrapper').hasClass('is-open')) {
				$('.search-fixed-wrapper').addClass('is-close');
				$('.search-fixed-wrapper').removeClass('is-open')
				$(el.currentTarget).css({'transform': 'translateY(' + 0 + 'px)'});
			}
			else if($('.search-fixed-wrapper').hasClass('is-close')) {
				$('.search-fixed-wrapper').addClass('is-open');
				$('.search-fixed-wrapper').removeClass('is-close')
				$(el.currentTarget).css({'transform': 'translateY(' + h + 'px)'});
			}

			if(this.firstClick) {
				$('.search-fixed-wrapper').addClass('is-open');
				$(el.currentTarget).css({'transform': 'translateY(' + h + 'px)'});
				this.firstClick = false
			}
		}
	},
	open: function() {
		if(Controller.device === 'SP') {
			this.scrollY = Controller.view.window.y;
			$('body').css({
				position: 'fixed',
				width: '100%',
				left: '0',
				top: -1 * this.scrollY,
			});
			$('.search-container').addClass('is-active');
		}

	},
	close: function(){
		if(Controller.device === 'SP') {
			var top = $('body').css('top');
			top = Math.abs(parseInt(top));
			$('body').removeAttr('style');
			$('body, html').prop({ scrollTop: top });
			$('.search-container').removeClass('is-active');
		}
	},
	clear: function() {

		$('.plan-panel-list').removeClass('is-active');

		$('.plan-panel').removeClass('active-hotel');
		$('.plan-panel').removeClass('active-season');
		$('.plan-panel').removeClass('active-category');
		$('.plan-panel').addClass('active-hotel');
		$('.plan-panel').addClass('active-season');
		$('.plan-panel').addClass('active-category');
		$('.search-column .input').prop('checked', false);

		var active = $('.plan-panel.active-hotel.active-season.active-category')
		$('.hit-number').text(active.length);
		$('.events .selected').text('');

		$('.none-text').remove()


		var timer = setTimeout(function(){
			$('.plan-panel-list').addClass('is-active');
		},300)
	},
	change: function() {
		$('.plan-panel-list').removeClass('is-active');
		if($('.none-text').get(0)) {
			$('.none-text').remove()
		}

		var searchText = [];
		var hotel = this.changeHotel();
		var season =  this.changeSeason();
		var category = this.changeCategory();

		if(hotel) {
			var arr1 = hotel.join(' / ');
			searchText.push(arr1);
		}

		if(season) {
			var arr2 = season.join(' / ');
			searchText.push(arr2);
		}

		if(category) {
			var arr3 = category.join(' / ');
			searchText.push(arr3);
		}

		var result = searchText.join(' / ')

		$('.events .selected').text(result)

		var active = $('.plan-panel.active-hotel.active-season.active-category')
		$('.hit-number').text(active.length);

		if(active.length === 0) {
			$('.plans-container-body').append('<p class="none-text">該当するプランはございません。<br class="pc-none">条件をご変更ください。</p>');
		}

		clearTimeout(timer);

		var timer = setTimeout(function(){
			$('.plan-panel-list').addClass('is-active');
		},300)

	},
	changeHotel: function() {

		if($('.search-hotel-item .input:checked').length > 0) {
			var arr = [];
			var textArr = [];
			$('.search-hotel-item .input:checked').each(function(){
				arr.push($(this).val());
				textArr.push($(this).nextAll('.text').text());
			});

			var number = [];

			for(var i = 0; i < arr.length; i++) {
				var element = document.getElementsByClassName('plan-panel-list');
				var target = element[0].getElementsByClassName(arr[i]);

				for(var e = 0; e < target.length; e++) {
					number.push($(target[e]).data('number'));
				}
			}

			var result = number.filter(function(x,i,self){
				return self.indexOf(x) === i;
			});

			$('.plan-panel').removeClass('active-hotel')

			if(result.length != 0) {
				for(var i = 0; i < result.length;i++) {
					$('.plan-panel[data-number="'+result[i] +'"]').addClass('active-hotel')
				}
			}
		} else {
			$('.plan-panel').addClass('active-hotel');
		}
		return textArr;
	},
	changeSeason: function() {

		if($('.search-season-item .input:checked').length > 0) {
			var arr = [];
			var textArr = [];
			$('.search-season-item .input:checked').each(function(){
				arr.push($(this).val());
				textArr.push($(this).nextAll('.text').text());
			});

			var number = [];

			for(var i = 0; i < arr.length; i++) {
				var element = document.getElementsByClassName('plan-panel-list');
				var target = element[0].getElementsByClassName(arr[i]);

				for(var e = 0; e < target.length; e++) {
					number .push($(target[e]).data('number'));
				}
			}

			var result = number;

			$('.plan-panel').removeClass('active-season')
			if(result.length != 0) {
				for(var i = 0; i < result.length;i++) {
					$('.plan-panel[data-number="'+result[i] +'"]').addClass('active-season')
				}
			}

		} else {
			$('.plan-panel').addClass('active-season')
		}


		return textArr;
	},
	changeCategory: function() {


		if($('.search-keyword-item .input:checked').length > 0) {

			var arr = [];
			var textArr = [];
			$('.search-keyword-item .input:checked').each(function(){
				arr.push($(this).val());
				textArr.push($(this).nextAll('.text').text());
			});

			var number = [];

			for(var i = 0; i < arr.length; i++) {
				var element = document.getElementsByClassName('plan-panel-list');
				var target = element[0].getElementsByClassName(arr[i]);

				for(var e = 0; e < target.length; e++) {
					number.push($(target[e]).data('number'));
				}
			}

			var result = number.filter(function(x,i,self){
				return self.indexOf(x) === i;
			});

			$('.plan-panel').removeClass('active-category')

			if(result.length != 0) {
				for(var i = 0; i < result.length;i++) {
					$('.plan-panel[data-number="'+result[i] +'"]').addClass('active-category')
				}
			}
		} else {
			$('.plan-panel').addClass('active-category')

		}

		return textArr;
	}
}

export default Search;
